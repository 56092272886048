window.fohlio ||= {};

export const isDev = process.env.NODE_ENV === 'development';

// Metadata
export const serviceApp = window.fohlio.service_app;
export const serviceName = window.fohlio.service_name;
export const serviceGroup = window.fohlio.service_group; // prod, stage, qa
export const serviceEnv = window.fohlio.service_env || window.fohlio.app_name; // us-east-1, demo, next, prev, main, develop, test01, test02, test03
export const serviceProfile = window.fohlio.service_profile || window.fohlio.app_env; // production, staging
export const serviceBuild = window.fohlio.core_front_build;

// TODO: Deprecate
export const appName = window.fohlio.app_name;
export const appEnv = window.fohlio.app_env;

// Configuration
export const feUrl = window.fohlio.fe_url;
export const beUrl = window.fohlio.be_url;
export const apiUrl = window.fohlio.api_url;
export const cableUrl = window.fohlio.cable_url;

// Dependencies
export const sentryDsn = window.fohlio.sentry_dsn;
export const logRocketId = window.fohlio.logrocket_id;
export const googleFontsApiKey = window.fohlio.google_fonts_api_key;
export const recaptchaSiteKey = window.fohlio.recaptcha_site_key;
export const stripePublishableKey = window.fohlio.stripe_publishable_key;
export const gtmId = window.fohlio.gtm_id;
export const intercomId = window.fohlio.intercom_id;
export const hubspotId = window.fohlio.hubspot_id;
export const totangoServiceId = window.fohlio.totango_service_id;
export const pendoId = window.fohlio.pendo_id;

// Micro Frontends
export const reactBaseUrl = window.fohlio.core_front_static_url || window.fohlio.react_base_url;
export const moodboardsBaseUrl = window.fohlio.moodboards_front_static_url || window.fohlio.moodboards_base_url;
export const boldBaseUrl = window.fohlio.bold_front_static_url || window.fohlio.bold_base_url || window.fohlio.frontend_bold_base_url;
